<template>
  <h1 class="title mr-[15px] mt-8 pb-6 md:mt-[45px] md:pb-[64px]">Sign in</h1>
  <div class="flex flex-col justify-start md:flex-row">
    <Form
      @submit="onSubmit"
      class="right_side-wrapper signin_form"
      :validation-schema="loginSchema"
      v-slot="{ errors, isSubmitting }"
    >
      <div class="right_side mb-0">
        <span class="account_label">E-mail Address</span>
        <Field
          name="email"
          type="email"
          class="input account_input"
          placeholder="E-mail Address"
          :class="errors.email ? 'error' : ''"
        />
        <span class="account_label">Password</span>
        <Field
          name="password"
          type="password"
          class="input account_input"
          placeholder="Password"
          :class="errors.password ? 'error' : ''"
        />
      </div>
      <p v-if="isLoggedIn" class="success">Logged in!</p>
      <button
        :disabled="isSubmitting"
        type="submit"
        class="next_step_button mt-[15px] h-[56px] w-full px-4 py-3 text-base leading-[17.6px] md:h-[64px] md:w-[148px] md:text-[18px] md:leading-[23.8px]"
      >
        Sign in
      </button>
    </Form>
  </div>
</template>
<script setup>
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import { Form, Field } from 'vee-validate'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

const router = useRouter()
const userStore = useUserStore()
const { isLoggedIn } = storeToRefs(userStore)
const axios = inject('axios')

const projectLink = ref(import.meta.env.VITE_PROJECT_URL)
const onSubmit = (values) => {
  const loginData = {
    email: values.email,
    password: values.password,
    website: projectLink.value
  }

  axios.post('/auth/login', loginData).then(function (response) {
    userStore.fill(response.data?.data)

    router.push('/account/ridehistory')
  })
}

const loginSchema = yup.object({
  email: yup.string().required().email().max(230),
  password: yup.string().required().max(230)
})
</script>
