<template>
  <svg
    class="scale-75 stroke-white"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11_2)">
      <path
        d="M24.5 27C26.433 27 28 25.433 28 23.5C28 21.567 26.433 20 24.5 20C22.567 20 21 21.567 21 23.5C21 25.433 22.567 27 24.5 27Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.5 20V14.9875C24.4978 13.3935 23.8641 11.8652 22.7375 10.7375L18 6"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M18 11V6H23" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M7.5 12C9.433 12 11 10.433 11 8.5C11 6.567 9.433 5 7.5 5C5.567 5 4 6.567 4 8.5C4 10.433 5.567 12 7.5 12Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 12V17.0125C7.50215 18.6065 8.13592 20.1348 9.2625 21.2625L14 26"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M14 21V26H9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_11_2">
        <rect width="32" height="32" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup></script>

<style scoped></style>
