<script setup></script>

<template>
  <svg
    class="fill-[#2B2D32] dark:fill-white"
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.54002 4.84526C4.26003 5.18473 3.73997 5.18473 3.45998 4.84526L0.614582 1.3954C0.238011 0.938832 0.562773 0.25 1.1546 0.25L6.8454 0.250001C7.43723 0.250001 7.76199 0.938832 7.38542 1.3954L4.54002 4.84526Z"
    />
  </svg>
</template>

<style scoped></style>
