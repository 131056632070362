<template>
  <div class="vehicle vehicle_popup mb-0 mt-[43px] flex-col !bg-white md:mt-0 md:w-[450px]">
    <div class="popup__summary_wrapper">
      <div
        v-if="props.distance != null"
        class="summary_item_wrapper popup_summary_item_wrapper border-placeholder border-b-[1px]"
      >
        <span class="summary_item_title popup_summary_title">Distance:</span>
        <p class="popup_summary_text">{{ props.distance }} KMS</p>
      </div>
      <div
        v-if="car_name != ''"
        class="summary_item_wrapper popup_summary_item_wrapper border-placeholder border-b-[1px]"
      >
        <span class="summary_item_title popup_summary_title">Car Type</span>
        <p class="popup_summary_text">{{ car_name }}</p>
      </div>
      <div
        v-if="props.reqs != null"
        class="summary_item_wrapper popup_summary_item_wrapper mb-0 pb-0"
      >
        <span class="summary_item_title popup_summary_title">Destinations & Requirements</span>
        <p class="popup_summary_text">{{ props.reqs }}</p>
      </div>
      <div
        v-if="props.consulting != null"
        class="summary_item_wrapper popup_summary_item_wrapper mb-0 pb-0"
      >
        <span class="summary_item_title popup_summary_title">Consulting message</span>
        <p class="popup_summary_text">{{ props.consulting }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps([
  'firstName',
  'lastName',
  'email',
  'phone',
  'notes',
  'distance',
  'transport',
  'reqs',
  'consulting'
])
let carObject = JSON.stringify(props.transport)
let carObjectParsed = JSON.parse(carObject)
let car_name
if (carObjectParsed != null) {
  car_name = carObjectParsed.class_full_name
} else {
  car_name = ''
}
</script>
