<script setup></script>

<template>
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.151367"
      y="28.9346"
      width="40"
      height="2"
      rx="1"
      transform="rotate(-45 0.151367 28.9346)"
      fill="#2B2D32"
    />
    <rect
      x="1.56543"
      y="0.650391"
      width="40"
      height="2"
      rx="1"
      transform="rotate(45 1.56543 0.650391)"
      fill="#2B2D32"
    />
  </svg>
</template>

<style scoped></style>
