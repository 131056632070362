import './assets/main.css'
import '~project_assets/project_style.css'
import 'vue-tel-input/vue-tel-input.css'

import { createApp } from 'vue'
import VueTelInput from 'vue-tel-input'
import App from '@/App.vue'
import router from '@/router/index.js'
import storage from '@/plugins/storage'
import appAxios from '@/plugins/axios'
import utils from '@/plugins/utils'
import VueYandexMetrika from 'vue3-yandex-metrika'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { createGtm } from '@gtm-support/vue-gtm'
import loadGoogleMapsApi from 'load-google-maps-api'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const globalOptions = {
  mode: 'auto',
  inputOptions: [
    {
      placeholder: 'Mobile Phone Number'
    }
  ],
  dropdownOptions: [
    {
      showDialCodeInSelection: true,
      showFlags: true
    }
  ]
}

const pinia = createPinia()
const app = createApp(App)

// loadGoogleMapsApi({
//   key: import.meta.env.VITE_APP_GOOGLE_API_KEY,
//   libraries: ['places', 'drawing', 'geometry'],
//   loading: 'async',
//   v: 'quarterly',
//   language: 'en'
// })

/**
 * Plugin install
 */
app.use(VueTelInput, globalOptions) // Define default global options here (optional)
app.use(router)
app.use(pinia)
app.use(appAxios, {
  baseUrl: import.meta.env.VITE_APP_API_URL
})

if (import.meta.env.VITE_GTM_ID) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      vueRouter: router // Pass the router instance to automatically sync with router (optional)
    })
  )
}

if (import.meta.env.VITE_APP_YANDEX_METRIKA) {
  app.use(VueYandexMetrika, {
    id: import.meta.env.VITE_APP_YANDEX_METRIKA,
    router: router,
    env: import.meta.env.MODE,
    // other options
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    }
  })
}

app.use(VueGoogleMaps)

/**
 * Provides install
 */
app.provide('storage', storage)
app.provide('utils', utils)

const regexLink =
  /^(?!.*(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})).*$/g
const regexIsHttps = /^(?!.*ftp|https|http|www).*$/g
const regexNameField = /^(?!.*[!@#$%^*_<>]).*$/g
const regexEmail =
  /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,3})$/g

app.provide('regexLink', regexLink)
app.provide('regexIsHttps', regexIsHttps)
app.provide('regexNameField', regexNameField)
app.provide('regexEmail', regexEmail)

Sentry.init({
  app,
  environment: import.meta.env.VITE_APP_ENV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        import.meta.env.VITE_APP_URL,
        import.meta.env.VITE_APP_PRODUCTION_URL
      ],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

loadGoogleMapsApi({
  key: import.meta.env.VITE_APP_GOOGLE_API_KEY,
  libraries: ['places', 'drawing', 'geometry'],
  loading: 'async',
  v: 'quarterly',
  language: 'en',
  timeout: 10000
}).then(async () => {
  app.mount('#app')
})
