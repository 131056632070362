<template>
  <div class="relative">
    <div>
      <input
        v-model="inputValue"
        @input="handleInput"
        @focus="handleFocus"
        type="text"
        :placeholder="placeholder"
        :class="classname"
      />
    </div>
    <AutocompleteList
      @selectSuggestion="selectSuggestion"
      :suggestions="suggestions"
      :showSuggestions="showSuggestions"
      :fieldType="fieldType"
    />
  </div>
</template>

<script setup>
import { nextTick, watch, toRefs, onMounted, defineModel, onBeforeUnmount } from 'vue'
import AutocompleteList from '@/components/custom/AutocompleteList.vue'
import { useTrustyStore } from '@/stores/trustyComplete'
import { storeToRefs } from 'pinia'
//
const trustyStore = useTrustyStore()
const {
  updatePlaceChoised,
  updateLastChoisePlace,
  findPlace,
  updatePathStartFinish,
  resetPathStartFinish
} = trustyStore
const { showSuggestions, lastChoisePlace } = storeToRefs(trustyStore)

const inputValue = defineModel()

const props = defineProps({
  suggestions: {
    type: Array,
    required: true
  },
  classname: {
    type: String,
    default: ''
  },
  minChars: {
    type: Number,
    default: 1
  },
  placeholder: {
    type: String,
    default: ''
  },
  fieldType: {
    type: String,
    default: 'pickup'
  },
  autoCompleteString: {
    type: String,
    default: ''
  }
})

const { suggestions, minChars, fieldType } = toRefs(props)

const emits = defineEmits([
  'get:suggestions',
  'update:suggestions',
  'clear:input',
  'select:suggestions'
])

const handleInput = () => {
  nextTick(() => {
    updatePlaceChoised(false, fieldType.value)
    if (inputValue.value?.length >= minChars.value) {
      emits('get:suggestions', inputValue.value, fieldType.value)
    } else {
      showSuggestions.value[fieldType.value] = false
    }
  })
}

// const clearInput = () => {
//   emits('clear:input')
// }

const selectSuggestion = (suggestion) => {
  inputValue.value = suggestion.description
  showSuggestions.value[fieldType.value] = false
  emits('select:suggestions', fieldType.value, suggestion.place_id)
  updateLastChoisePlace(
    {
      formatted_address: suggestion.description,
      place_id: suggestion.place_id
    },
    fieldType.value
  )
  updatePlaceChoised(true, fieldType.value)
}

const closeDropdown = ({ target }) => {
  if (
    !target.closest('#autocomplete-list') &&
    !target.closest('#duplicate_ride') &&
    !target.closest('#book_return_ride')
  ) {
    showSuggestions.value[fieldType.value] = false
    if (inputValue.value == '' && !props.autoCompleteString) {
      // updateLastChoisePlace({ formatted_address: '', place_id: '' }, fieldType.value)
    } else {
      inputValue.value = lastChoisePlace.value[fieldType.value].formatted_address
    }
    suggestions.value = []
    emits('update:suggestions', [])
  }
}

const handleFocus = () => {
  let closeModal = fieldType.value == 'pickup' ? 'dropoff' : 'pickup'
  showSuggestions.value[closeModal] = false
  emits('update:suggestions', [])

  handleInput()
}

const autoComplete = () => {
  inputValue.value = props.autoCompleteString
  updateLastChoisePlace(
    { formatted_address: props.autoCompleteString, place_id: '' },
    fieldType.value
  )
  updatePlaceChoised(true, fieldType.value)

  findPlace(props.autoCompleteString, (data) => {
    if (data.status == 'OK' && data.candidates.length > 0) {
      updatePathStartFinish(data.candidates[0].geometry.location, fieldType.value)
    }
  })
}
//
watch(inputValue.value, (newVal) => {
  if (newVal.length < minChars.value) {
    emits('update:suggestions', [])
    showSuggestions.value[fieldType] = false
  }
})

onMounted(() => {
  if (props.autoCompleteString) autoComplete()
  document.addEventListener('click', closeDropdown)
})
onBeforeUnmount(() => {
  showSuggestions.value[fieldType.value] = false
  resetPathStartFinish()
  document.removeEventListener('click', closeDropdown)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', closeDropdown)
})
</script>
