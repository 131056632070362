<template>
  <Transition>
    <div
      v-if="!isLoaded"
      class="pagePreloader fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center duration-300"
    >
      <div class="flex h-[200px] w-[200px] items-center justify-center">
        <svg
          class="fill-main absolute animate-spin"
          style="shape-rendering: auto"
          width="200px"
          height="200px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" stroke="none"></path>
        </svg>
        <img :src="logos[mode]" class="w-3/5" />
      </div>
    </div>
  </Transition>
</template>

<script setup>
import darklogo from '~project_assets/images/logo.png'
import lightlogo from '~project_assets/images/logo-light.png'
import { reactive, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/stores/main'

const mainStore = useMainStore()

const isLoaded = ref(false)

const { mode } = storeToRefs(mainStore)

let logos = reactive({
  light: lightlogo,
  dark: darklogo
})

window.addEventListener('load', () => (isLoaded.value = true))
</script>

<style scoped>
.v-leave-to {
  opacity: 0;
}
</style>
