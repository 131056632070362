import { defineStore } from 'pinia'
import { inject, ref, watch } from 'vue'

const STORE_NAME = 'main'
// const MODE_LOCALSTORAGE_KEY = 'mode'

export const useMainStore = defineStore(STORE_NAME, () => {
  const storage = inject('storage')

  const paymentCode = ref(null)
  const paymentLink = ref(null)
  const paymentSession = ref(null)
  const paymentComplete = ref(false)
  const isRequesting = ref(false)

  /**
   * Types of flow:
   * - platform
   * - mainsite
   * - mailcar
   * - transaction
   */
  const flow = ref('platform')
  const ssid = ref(null)

  const updatePaymentComplete = (value) => {
    paymentComplete.value = value
    storage.setItem('paymentComplete', value)
  }

  const getDefaultMode = () => {
    let mode = storage.getItem('mode')

    if (!mode) {
      return 'dark'

      // try {
      //   let isDark = window.matchMedia('(prefers-color-scheme:dark)').matches
      //   return isDark ? 'dark' : 'light'
      // } catch (e) {
      //   const date = new Date()
      //   const hour = date.getHours()
      //   if (hour >= '07' && hour < '17') {
      //     storage.setItem(MODE_LOCALSTORAGE_KEY, 'light', 'session')
      //     return 'light'
      //   } else {
      //     storage.setItem(MODE_LOCALSTORAGE_KEY, 'dark', 'session')
      //     return 'dark'
      //   }
      // }
    }

    return mode
  }

  const toggleDarkClass = () => {
    if (mode.value === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  const mode = ref(getDefaultMode())

  toggleDarkClass()

  watch(mode, toggleDarkClass)

  const paymentVisited = ref(null)
  const priceChanged = ref(null)

  function update(data) {
    mode.value = data
    storage.setItem('mode', data)
  }

  function updatePriceChanged(data) {
    priceChanged.value = data
  }

  const $reset = () => {
    paymentVisited.value = null
    isRequesting.value = false
  }

  return {
    mode,
    ssid,
    paymentVisited,
    priceChanged,
    paymentCode,
    paymentLink,
    paymentSession,
    isRequesting,
    flow,
    update,
    updatePriceChanged,
    $reset,
    updatePaymentComplete
  }
})
