import axios from 'axios'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'

export function useFetcher(options = {}) {
  axios.defaults.headers.common = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }

  const userStore = useUserStore()
  const { token, isLoggedIn } = storeToRefs(userStore)

  let headersData = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }

  if (isLoggedIn.value) {
    headersData['Authorization'] = `Bearer ${token.value}`
  }

  axios.defaults.headers.common = headersData

  const axiosInstance = axios.create({
    baseURL: options.baseUrl
  })

  axiosInstance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error?.response?.status === 401) {
        userStore.preventLogout()
      }
      return Promise.reject(error)
    }
  )

  return { axiosInstance }
}
