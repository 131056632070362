<template>
  <PagePreloader />
  <teleport to="body">
    <PleaseWaitPreloader v-if="isRequesting" />
  </teleport>
  <StayWithUs v-if="flow !== 'mainsite'" />
  <HeaderBlock v-if="flow !== 'mainsite'" />
  <ReloadPrompt />
  <router-view :key="$route.path" />
  <ProjectVersion v-if="flow !== 'mainsite'" />
</template>
<script setup>
import { onBeforeMount, onMounted } from 'vue'
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'

import HeaderBlock from '@/blocks/HeaderBlock.vue'
import ReloadPrompt from '@/ReloadPrompt.vue'
import PagePreloader from '@/components/PagePreloader.vue'
import ProjectVersion from '@/components/ProjectVersion.vue'
import StayWithUs from '@/components/StayWithUs.vue'
import PleaseWaitPreloader from '@/components/PleaseWaitPreloader.vue'
import { useCentrifugoStore } from '@/stores/centrifugo'

const mainStore = useMainStore()
const centrifugoStore = useCentrifugoStore()

const { flow, ssid, isRequesting } = storeToRefs(mainStore)

onBeforeMount(() => {
  let channels = []

  // if (isLoggedIn.value) {
  //   const userId = userStore.user.id
  //   channels.push({
  //     event: 'as.orders.request.status',
  //     name: `Orders.History.Request.Status.Change.${userId}`,
  //     action: (data) => {
  //       if (data.status === 'succeed') {
  //         ridesHistoryStore.updateRequestStatus('succeed')
  //         isRequestSuccessful.value = true
  //       } else {
  //         ridesHistoryStore.updateRequestStatus('refused')
  //         isRequestRefused.value = true
  //       }
  //       isRequestPending.value = false
  //     }
  //   })
  //   channels.push({
  //     event: 'as.orders',
  //     name: `Orders.History.Ready.${userId}`,
  //     action: (data) => {
  //       if (data.orders.length !== 0) {
  //         isRidesFetched.value = true
  //         rides.value = data.orders
  //         ridesHistoryStore.updateRides(data.orders)
  //       } else {
  //         isNoRides.value = true
  //         ridesHistoryStore.updateRequestStatus('empty')
  //         isRequestRefused.value = false
  //         isRequestSuccessful.value = false
  //         isRequestPending.value = false
  //       }
  //     }
  //   })
  // }

  if (flow.value === 'mainsite') {
    channels.push({
      event: 'fill_form',
      name: `dli-` + ssid.value,
      action: () => {
        //console.log(data)
      }
    })
  }

  centrifugoStore.connect(channels)
})

onMounted(() => {})
</script>
