<template>
  <div v-if="props.isOpen" class="fixed inset-0 z-50 flex items-center justify-center">
    <div class="fixed inset-0 bg-black opacity-50" @click="closePopUp"></div>
    <div
      class="relative z-10 w-full max-w-xl rounded-[35px] bg-white p-8 text-white shadow-lg dark:bg-[#272729]"
    >
      <button
        @click="closePopUp"
        aria-label="close"
        class="closeButton absolute right-4 top-4 inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-black dark:text-white"
        type="button"
      >
        <svg
          class="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clip-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            fill-rule="evenodd"
          ></path>
        </svg>
      </button>
      <h1 class="title text-background mb-6 text-6xl font-bold dark:text-white">Sign Up</h1>
      <p class="mb-6 text-black dark:text-white">
        To book additional rides more efficiently and quickly, it's best to register. It only takes
        a few seconds and will enhance your experience on our site.
      </p>
      <Form @submit="submitForm" :validation-schema="validationSchema">
        <div class="mb-6 flex gap-4">
          <label for="" class="w-full space-y-2 text-gray-600 dark:text-[#878787]">
            <span>First Name <ErrorMessage class="text-red-600" name="first_name" /></span>
            <Field
              name="first_name"
              type="text"
              class="input ym-record-keys w-full"
              placeholder="First Name"
            />
          </label>
          <label for="" class="w-full space-y-2 text-gray-600 dark:text-[#878787]">
            <span>Last Name <ErrorMessage class="text-red-600" name="last_name" /></span>
            <Field
              name="last_name"
              type="text"
              class="input ym-record-keys w-full"
              placeholder="Last Name"
            />
          </label>
        </div>
        <div class="mb-6">
          <label for="" class="w-full space-y-2 text-gray-600 dark:text-[#878787]">
            <span
              >E-mail Address
              <ErrorMessage class="text-red-600" name="email" />
              <span class="float-end text-red-600" v-if="mailBusy">Mail is already registered</span>
            </span>
            <Field
              name="email"
              type="email"
              class="input ym-record-keys w-full"
              :class="mailBusy ? 'outline outline-1 outline-red-600' : ''"
              @focus="mailBusy = false"
              placeholder="E-mail Address"
            />
          </label>
        </div>
        <div class="mb-6">
          <label for="" class="w-full space-y-2 text-gray-600 dark:text-[#878787]">
            <span
              >Mobile Phone Number
              <ErrorMessage class="text-red-600" name="phone" />
            </span>
            <Field name="phone" v-slot="{ field }">
              <vue-tel-input
                v-bind="field"
                maxlength="230"
                @country-changed="countryChanged"
                v-on:beforeinput="utils.isNumber($event)"
                class="input ym-record-keys m-0 w-full"
                mode="auto"
                v-model="phone"
                :autoFormat="false"
                inputClasses="input"
                :dropdownOptions="{
                  showFlags: true,
                  showDialCodeInList: true,
                  showDialCodeInSelection: true
                }"
              ></vue-tel-input>
            </Field>
          </label>
        </div>
        <button type="submit" class="button w-full">Sign Up</button>
      </Form>
      <p class="mt-4 space-x-1.5 text-center text-black dark:text-white">
        <span>If you already have an account, please</span>
        <button @click="handleSignInClick" type="button" class="text-main">log in here</button>
      </p>
    </div>
  </div>
</template>

<script setup>
import { Form, Field, ErrorMessage } from 'vee-validate'
import { ref, inject } from 'vue'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import utils from '@/plugins/utils'
import * as yup from 'yup'

const props = defineProps(['isOpen'])
const emit = defineEmits(['close', 'open-signin'])

const validationSchema = yup.object({
  phone: yup.string().required('Phone number is required').min(3, 'Minimum 3 symbols'),
  email: yup.string().email('Email must be a valid email').required('Email is required').max(230),
  first_name: yup.string().required('Required').min(2, 'Minimum 2 symbols'),
  last_name: yup.string().required('Required').min(2, 'Minimum 2 symbols')
})

const axios = inject('axios')
const router = useRouter()

const userStore = useUserStore()

const phone = ref('')
const country_prefix = ref('')
const code = ref('')

const mailBusy = ref(false)

const projectLink = import.meta.env.VITE_PROJECT_URL

const countryChanged = (e) => {
  country_prefix.value = e.iso2
  code.value = e.dialCode
}

const closePopUp = () => {
  emit('close')
}

const submitForm = (data) => {
  data.code = code.value
  data.country_prefix = country_prefix.value
  data.website = projectLink

  axios
    .post('/auth/register', data)
    .then((response) => {
      if (response?.data?.status == 'success') {
        userStore.fill(response?.data?.data)
        router.push({ name: 'ridehistory' })
      } else {
        // error
      }
    })
    .catch((error) => {
      if (error?.response) {
        if (error.response?.status === 409) mailBusy.value = true
      }
      console.log(error)
    })
}

const handleSignInClick = () => {
  closePopUp()
  emit('open-signin')
}
</script>
