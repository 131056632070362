<script setup></script>

<template>
  <svg
    class="burger_icon cursor-pointer"
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="12"
    viewBox="0 0 40 12"
    fill="none"
  >
    <rect width="40" height="2" rx="1" fill="white" />
    <rect y="10" width="40" height="2" rx="1" fill="white" />
  </svg>
</template>

<style scoped></style>
