import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', () => {
  const user = ref(null)

  const token = computed(() => user.value?.token)
  const isLoggedIn = computed(() => !!user.value?.id)
  const fullName = computed(() => {
    return isLoggedIn.value ? user.value.name + ' ' + user.value.last_name : false
  })

  function fill(data) {
    user.value = data
    localStorage.setItem('user', JSON.stringify(data))
  }

  function update(data) {
    user.value = { ...user.value, ...data }
    localStorage.setItem('user', JSON.stringify(user.value))
  }

  function preventLogout() {
    user.value = null
    localStorage.removeItem('user')
  }

  const fillData = () => {
    const userStorage = localStorage.getItem('user')
    if (userStorage) {
      return fill(JSON.parse(userStorage))
    } else {
      return fill(null)
    }
  }

  fillData()

  return {
    token,
    user,
    fullName,
    isLoggedIn,
    fill,
    update,
    preventLogout
  }
})
