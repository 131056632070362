import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useSideBarStore = defineStore('sidebar', () => {
  const isSideBarOpen = ref(false)
  function update(data) {
    isSideBarOpen.value = data
    if (!data) {
      setTimeout(() => {
        document.body.classList.remove('overflow-y-hidden')
      }, 500)
    } else {
      document.body.classList.add('overflow-y-hidden')
    }
  }
  return {
    update,
    isSideBarOpen
  }
})
