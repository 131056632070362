<template>
  <div class="flex h-full flex-col justify-between">
    <div v-if="fromServiceData">
      <div>
        <div class="mt-[21px] flex flex-col flex-wrap justify-between md:flex-row md:items-end">
          <h1 class="title mr-[15px]">
            Thanks for<br />
            providing this data!
          </h1>
        </div>
        <p class="subscribe_text payment_message mt-[30px]">
          {{ paymentMessage }}
        </p>
        <SummaryInfo
          classnames="summary_title_button_wrapper dark:bg-[#272729] mt-[47px] md:mt-[56px] border-b-[1px] border-placeholder"
          title="Service Data - Completed"
          disabled="true"
          prepare="serviceData"
        />
        <SummaryInfo
          classnames="summary_title_button_wrapper rounded-b-[40px] border-placeholder form_to_edit dark:bg-[#333639] mt-[-38px]"
          title="Contact Data - Completed"
          disabled="false"
          prepare="contactData"
        />
      </div>
      <div class="mt-4 grid gap-4 md:mt-8 lg:grid-cols-2 lg:gap-8">
        <div
          class="flex flex-col items-start justify-between gap-y-6 rounded-[40px] bg-[#DDE5DC] px-6 py-8 dark:bg-[#272729]"
        >
          <p class="subscribe_text">
            Please, Subscribe for your Newsletters to be Informed about our News and Promotions
          </p>
          <form
            v-on:submit.prevent="emailSubscribe"
            class="flex w-full items-center justify-start gap-3 max-sm:flex-col"
          >
            <span
              v-if="userEmailValidation"
              name="email"
              class="ml-5 inline-block w-full text-red-600 max-sm:ml-0 max-sm:text-center"
              >{{ userEmailValidation }}</span
            >

            <span
              v-if="emailSubscribeSuccess"
              name="email"
              class="text-main ml-5 inline-block w-full max-sm:ml-0 max-sm:text-center"
              >Success</span
            >
            <div class="flex w-full gap-3 max-sm:flex-col">
              <input
                class="input subscribe_input dark:!border-[#878787]"
                type="text"
                name="EMAIL"
                autocomplete="off"
                @input="userEmailValidation = false"
                placeholder="E-mail Address"
                data-required="true"
                v-model="userEmail"
                required
              />
              <input type="hidden" value="1" name="OPT_IN" />
              <input type="hidden" name="email_address_check" value="" />
              <input type="hidden" name="locale" value="en" />

              <button type="submit" class="subscribe_button">Subscribe</button>
            </div>
          </form>
        </div>
        <div
          class="bg-main flex flex-grow flex-col items-start justify-between gap-y-6 rounded-[40px] px-6 py-8 sm:p-8"
        >
          <div class="space-y-3">
            <span class="text-background text-2xl/[110%] font-bold">New Service</span>
            <p class="text-background text-base/[110%] sm:text-[18px]/[138%]">
              If you wish to book other services please click here.
            </p>
          </div>
          <button
            type="submit"
            @click="openSignUp"
            class="bg-background text-main w-full rounded-[37px] py-[22px] text-[18px]/[110%] font-semibold"
          >
            New Service
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="fromStayWithUs">
      <div class="mt-[21px] flex flex-col flex-wrap justify-between md:flex-row md:items-end">
        <h1 class="title mr-[15px]">Thanks for<br />your Request!</h1>
        <p class="subtitle">
          If you have any Questions Feel<br />Free to Send us
          <a class="text-main" :href="'mailto:' + email">Email</a> or
          <a class="text-main" :href="'tel:' + phone">Call us</a>
        </p>
      </div>
      <p class="subscribe_text payment_message mt-[30px]">{{ paymentMessage }}</p>
      <div class="mt-4 grid gap-4 md:mt-8 lg:grid-cols-2 lg:gap-8">
        <div
          class="flex flex-col items-start justify-between gap-y-6 rounded-[40px] bg-[#DDE5DC] px-6 py-8 dark:bg-[#272729]"
        >
          <p class="subscribe_text">
            Please, Subscribe for your Newsletters to be Informed about our News and Promotions
          </p>
          <form
            v-on:submit.prevent="emailSubscribe"
            class="flex w-full items-center justify-start gap-3 max-sm:flex-col"
          >
            <span
              v-if="userEmailValidation"
              name="email"
              class="ml-5 inline-block w-full text-red-600 max-sm:ml-0 max-sm:text-center"
              >{{ userEmailValidation }}</span
            >

            <span
              v-if="emailSubscribeSuccess"
              name="email"
              class="text-main ml-5 inline-block w-full max-sm:ml-0 max-sm:text-center"
              >Success</span
            >
            <div class="flex w-full gap-3 max-sm:flex-col">
              <input
                class="input subscribe_input dark:!border-[#878787]"
                type="text"
                name="EMAIL"
                autocomplete="off"
                @input="userEmailValidation = false"
                placeholder="E-mail Address"
                data-required="true"
                v-model="userEmail"
                required
              />
              <input type="hidden" value="1" name="OPT_IN" />
              <input type="hidden" name="email_address_check" value="" />
              <input type="hidden" name="locale" value="en" />

              <button type="submit" class="subscribe_button">Subscribe</button>
            </div>
          </form>
        </div>
        <div
          class="bg-main flex flex-grow flex-col items-start justify-between gap-y-6 rounded-[40px] px-6 py-8 sm:p-8"
        >
          <div class="space-y-3">
            <span class="text-background text-2xl/[110%] font-bold">New Service</span>
            <p class="text-background text-base/[110%] sm:text-[18px]/[138%]">
              If you wish to book other services please click here.
            </p>
          </div>
          <button
            type="submit"
            @click="openSignUp"
            class="bg-background text-main w-full rounded-[37px] py-[22px] text-[18px]/[110%] font-semibold"
          >
            New Service
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mt-[21px] flex flex-col flex-wrap justify-between md:flex-row md:items-end">
        <h1 class="title mr-[15px]">Thanks for<br />your Request!</h1>
        <p class="subtitle">
          If you have any Questions Feel<br />Free to Send us
          <a class="text-main" :href="'mailto:' + email">Email</a> or
          <a class="text-main" :href="'tel:' + phone">Call us</a>
        </p>
      </div>
      <p class="subscribe_text payment_message mt-[30px]">{{ paymentMessage }}</p>
      <SummaryInfo
        classnames="summary_title_button_wrapper border-b-[1px] border-placeholder"
        title="Service Data"
        disabled="true"
        prepare="serviceData"
      />
      <SummaryInfo
        classnames="bg-white shadow-none relative z-20 -mt-8 px-4 py-6 dark:bg-[#333639] sm:p-8 rounded-[40px]"
        title="Account Data"
        disabled="false"
        prepare="contactData"
      />
      <div class="mt-4 grid gap-4 md:mt-8 lg:grid-cols-2 lg:gap-8">
        <div
          class="flex flex-col items-start justify-between gap-y-6 rounded-[40px] bg-[#DDE5DC] px-6 py-8 dark:bg-[#272729]"
        >
          <p class="subscribe_text">
            Please, Subscribe for your Newsletters to be Informed about our News and Promotions
          </p>
          <form
            v-on:submit.prevent="emailSubscribe"
            class="w-full flex-col items-center justify-start gap-3 max-sm:flex-col"
          >
            <span
              v-if="userEmailValidation"
              name="email"
              class="ml-5 inline-block w-full text-red-600 max-sm:ml-0 max-sm:text-center"
              >{{ userEmailValidation }}</span
            >

            <span
              v-if="emailSubscribeSuccess"
              name="email"
              class="text-main ml-5 inline-block w-full max-sm:ml-0 max-sm:text-center"
              >Success</span
            >
            <div class="flex w-full gap-3 max-sm:flex-col">
              <input
                class="input subscribe_input dark:!border-[#878787]"
                type="text"
                name="EMAIL"
                autocomplete="off"
                @input="userEmailValidation = false"
                placeholder="E-mail Address"
                data-required="true"
                v-model="userEmail"
                required
              />
              <input type="hidden" value="1" name="OPT_IN" />
              <input type="hidden" name="email_address_check" value="" />
              <input type="hidden" name="locale" value="en" />

              <button type="submit" class="subscribe_button">Subscribe</button>
            </div>
          </form>
        </div>
        <div
          class="bg-main flex flex-grow flex-col items-start justify-between gap-y-6 rounded-[40px] px-6 py-8 sm:p-8"
        >
          <div class="space-y-3">
            <span class="text-background text-2xl/[110%] font-bold">New Service</span>
            <p class="text-background text-base/[110%] sm:text-[18px]/[138%]">
              If you wish to book other services please click here.
            </p>
          </div>
          <button
            type="submit"
            @click="openSignUp"
            class="bg-background text-main w-full rounded-[37px] py-[22px] text-[18px]/[110%] font-semibold"
          >
            New Service
          </button>
        </div>
      </div>
    </div>
    <SignUp :isOpen="isSignUpOpen" @close="isSignUpOpen = false" @open-signin="openSignIn" />
    <SignIn :isOpen="isSignInOpen" @close="isSignInOpen = false" @open-signup="openSignUp" />
  </div>
</template>

<script setup>
import SignIn from '../components/SignIn.vue'
import SignUp from '../components/SignUp.vue'
import SummaryInfo from '@/components/SummaryInfo.vue'
import { onMounted, onBeforeMount, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useContactsStore } from '@/stores/contacts'
import * as yup from 'yup'

const isSignUpOpen = ref(false)
const isSignInOpen = ref(false)

import { useOrderStore } from '@/stores/order'
import { useUserStore } from '@/stores/user'
import { useProfileCompletionStore } from '@/stores/profile_completion'
import { storeToRefs } from 'pinia'

const route = useRoute()
const router = useRouter()

const phone = ref(import.meta.env.VITE_PROJECT_PHONE)
const email = ref(import.meta.env.VITE_PROJECT_EMAIL)
const userEmail = ref('')
const userEmailValidation = ref(false)
const emailSubscribeSuccess = ref(false)
const profileCompletionStore = useProfileCompletionStore()
const orderStore = useOrderStore()
const contactsStore = useContactsStore()
const userStore = useUserStore()

const { profile, fromServiceData } = storeToRefs(profileCompletionStore)
const { orderData, fromStayWithUs, tour } = storeToRefs(orderStore)
const { isLoggedIn } = storeToRefs(userStore)

let paymentMessage = computed(() => {
  const messageRequest =
    'We are currently receiving a very high volume of requests and answering you may take longer than normal.'
  const messageValue = `Dear ${orderData.value.first_name} ${orderData.value.last_name},
                        thank you very much, we have received your payment of Euro ${orderData.value.total}.
                        We will now process the booking and arrange the service, when this is done you will receive a confirmation email.`

  if (orderData.value.type_of_service === 'toursRoadshows') return messageRequest

  return route.query.redirect_status || profile.value.first_name !== ''
    ? messageValue
    : messageRequest
})

const emailSubscribe = (e) => {
  try {
    emailRules.validateSync(userEmail.value)

    const url = `https://fabb078e.sibforms.com/serve/MUIFAFW0qI_VDlfier73h0uBz-YLYLSzWxv7EYpVUTIz5i38_PUcxJ0p9ZyaceQq-XxzaUB1UFPYEwICLmr4YwK6BaWvqCOtjyrG-7lc1PR9Q2mIi9S7wCfdoa1jzRGIAMMPT6FKP8Sttz4JSrqDUTPm96gxlrFvblaiwnaLmoFNFuuQautxzm8MmH6Rxb-EshqcFqezVQmVFplz?isAjax=1`
    let xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
      if (xhr.readyState == XMLHttpRequest.DONE) {
        if (JSON.parse(xhr.responseText)?.success) {
          emailSubscribeSuccess.value = true
          userEmailValidation.value = false
        }
      }
    }
    xhr.open('POST', url)
    xhr.send(new FormData(e.target))
  } catch (e) {
    userEmailValidation.value = e.errors[0]
  }
}

onMounted(() => {})

onBeforeMount(async () => {
  if (tour.value) {
    contactsStore.add(orderData.value)
  }
})

const emailRules = yup
  .string()
  .email('Email must be email')
  .required('Email is required')
  .max(230)
  .test(
    'email-dot',
    'The email address must contain a dot (.) in the second part (after the @ symbol), as addresses without a dot will not be accepted',
    (value) => {
      return value.includes('.')
    }
  )

const openSignUp = () => {
  if (isLoggedIn.value) {
    router.push({ name: 'ridehistory' })
  } else {
    isSignUpOpen.value = true
    isSignInOpen.value = false
  }
}

const openSignIn = () => {
  isSignInOpen.value = true
  isSignUpOpen.value = false
}
</script>
