import { computed, ref } from 'vue'
import moment from 'moment'

// by convention, composable function names start with "use"
export function useDatePicker() {
  const dateInternal = ref(null)
  const dateTime = ref(null)
  const datePicker = ref(null)
  const timeSetFirstTime = ref(true)
  const initialDate = ref(null)
  const datePickerRef = ref(null)

  const handleInternal = (value) => {
    if (timeSetFirstTime.value && value) {
      initialDate.value = new Date(value.setHours(0, 0))
      datePicker.value = initialDate.value
      timeSetFirstTime.value = false
    } else if (!timeSetFirstTime.value && value) {
      initialDate.value = value
    }
  }

  const timeOptions = computed(() => {
    const allOptions = Array.from({ length: 24 }, (_, i) => i)
      .map((hour) =>
        [0, 15, 30, 45].map(
          (minute) => `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
        )
      )
      .flat()

    const minMinutes = minTime.value.hours * 60 + minTime.value.minutes

    if (
      moment(initialDate.value).format('YYYY-MM-DD') === moment(minDate.value).format('YYYY-MM-DD')
    ) {
      return allOptions.filter((option) => {
        const [hours, minutes] = option.split(':').map(Number)
        const totalMinutes = hours * 60 + minutes
        return totalMinutes >= minMinutes
      })
    }

    return allOptions
  })

  const toDay = computed(() => {
    const rightNow = new Date()
    const nowRome = moment(
      Date.parse(rightNow.toLocaleString('en-US', { timeZone: 'Europe/Rome' }))
    )

    return nowRome.hours() < 19
  })

  const minDate = computed(() => {
    const rightNow = new Date()
    const nowRome = moment(
      Date.parse(rightNow.toLocaleString('en-US', { timeZone: 'Europe/Rome' }))
    )

    if (toDay.value) {
      return nowRome.toDate()
    }

    return nowRome.add(1, 'days').toDate()
  })

  const minTime = computed(() => {
    const selectedNow = dateInternal.value ?? new Date()

    const selectedNowRome = moment(
      Date.parse(selectedNow.toLocaleString('en-US', { timeZone: 'Europe/Rome' }))
    )
    const nowRome = moment(
      Date.parse(new Date().toLocaleString('en-US', { timeZone: 'Europe/Rome' }))
    )
    if (
      selectedNowRome.dayOfYear() - nowRome.dayOfYear() === 1 ||
      (selectedNowRome.dayOfYear() - nowRome.dayOfYear() === 0 && nowRome.hours() < 9)
    ) {
      return { hours: 11, minutes: 0 }
    }

    if (selectedNowRome.dayOfYear() - nowRome.dayOfYear() === 0 && toDay.value) {
      return { hours: nowRome.hours() + 2, minutes: nowRome.minute() }
    }

    return { hours: 0, minutes: 0 }
  })

  const dateFormat = (date) => {
    return moment(date).format('DD/MM/YYYY H:mm')
  }

  return {
    dateInternal,
    minDate,
    minTime,
    dateTime,
    datePicker,
    initialDate,
    timeSetFirstTime,
    datePickerRef,
    timeOptions,
    handleInternal,
    dateFormat
  }
}
