<template>
  <div
    v-if="showSuggestions[fieldType] && suggestions.length > 0"
    class="dark:bg-background absolute left-0 top-[calc(100%+8px)] z-[21] w-full rounded-2xl border-[#878787] bg-white p-4 text-base/[17.6px] font-bold dark:border-[#3D4043] md:w-[calc(100%+200px)] md:text-lg/[23.8px]"
    id="autocomplete-list"
  >
    <component
      v-for="(item, index) in suggestions"
      :key="index"
      :is="componentsOrder[index]"
      @click="emits('selectSuggestion', item)"
      class="relative flex cursor-pointer rounded-full border-none bg-transparent p-5 text-[#878787] duration-300 hover:bg-[#CCF2C8] hover:text-black hover:dark:bg-[#333639] dark:hover:text-white"
      :class="{ 'pointer-events-none': lastChoisePlace.place_id === item.place_id }"
    >
      <component :is="parsePlaceType(item)" :place="item" class="group truncate"> </component>
      <div
        v-if="lastChoisePlace.place_id === item.place_id"
        class="bg-main absolute right-5 size-max rounded-full px-2 py-0.5 text-sm font-medium text-black"
      >
        Selected
      </div>
    </component>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import collect from 'collect.js'
import FirstComponent from '@/components/custom/Autocomplete/order/FirstComponent.vue'
import SecondComponent from '@/components/custom/Autocomplete/order/SecondComponent.vue'
import ThirdComponent from '@/components/custom/Autocomplete/order/ThirdComponent.vue'
import FourthComponent from '@/components/custom/Autocomplete/order/FourthComponent.vue'
import FifthComponent from '@/components/custom/Autocomplete/order/FifthComponent.vue'
import AirportOption from '@/components/custom/Autocomplete/AirportOption.vue'
import PlaceOption from '@/components/custom/Autocomplete/PlaceOption.vue'
import HotelOption from '@/components/custom/Autocomplete/HotelOption.vue'
import TrainOption from '@/components/custom/Autocomplete/TrainOption.vue'
import { useTrustyStore } from '@/stores/trustyComplete'
import { storeToRefs } from 'pinia'

const trustyStore = useTrustyStore()
const { lastChoisePlace } = storeToRefs(trustyStore)

const props = defineProps(['suggestions', 'showSuggestions', 'fieldType'])
const emits = defineEmits(['selectSuggestion'])

const fieldType = ref(props.fieldType)
const suggestions = ref(props.suggestions)
const showSuggestions = ref(props.showSuggestions)

const componentsOrder = [
  FirstComponent,
  SecondComponent,
  ThirdComponent,
  FourthComponent,
  FifthComponent
]

const orderType = collect({
  airport: AirportOption,
  other: PlaceOption,
  lodging: HotelOption,
  train_station: TrainOption,
  transit_station: TrainOption
})

const parsePlaceType = (item) => {
  let types = collect(item.types).intersect(orderType.keys())
  return orderType.get(types.first() ?? 'other')
}

watch(
  () => [props.suggestions, props.showSuggestions],
  (value) => {
    suggestions.value = value[0]
    showSuggestions.value = value[1]
  }
)
</script>
