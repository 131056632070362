<script setup></script>

<template>
  <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.28801 1.87399L6.22701 0.812988L0.0400085 7L6.22701 13.187L7.28801 12.126L2.91101 7.75H22V6.25H2.91101L7.28801 1.87399Z"
      class="go_back_button_svg"
    />
  </svg>
</template>

<style scoped></style>
