import { ref } from 'vue'
import { defineStore } from 'pinia'
import { collect } from 'collect.js'
import axios from 'axios'

export const useTrustyStore = defineStore('trustyComplete', () => {
  const data = ref([])
  const inputValue = ref('')
  const pickupRef = ref('')
  const dropoffRef = ref('')
  const pathStartFinish = ref({
    pickup: {
      lat: null,
      lng: null
    },
    dropoff: {
      lat: null,
      lng: null
    },
    valid: {
      pickup: false,
      dropoff: false
    }
  })

  const activeAuto = ref('')

  const showSuggestions = ref({
    pickup: false,
    dropoff: false
  })

  const lastChoisePlace = ref({
    pickup: {
      formatted_address: '',
      place_id: ''
    },
    dropoff: {
      formatted_address: '',
      place_id: ''
    }
  })

  const placeChoised = ref({
    pickup: false,
    dropoff: false
  })

  const apiInput = import.meta.env.VITE_APP_API_URL + '/autocomplete'

  const updatePlaceChoised = (val, type) => {
    if (type == 'pickup') {
      placeChoised.value.pickup = val
    } else {
      placeChoised.value.dropoff = val
    }
  }

  const resetPathStartFinish = () => {
    pathStartFinish.value = {
      pickup: {
        lat: null,
        lng: null
      },
      dropoff: {
        lat: null,
        lng: null
      },
      valid: {
        pickup: false,
        dropoff: false
      }
    }
  }

  const updateLastChoisePlace = (val, type) => {
    if (type == 'pickup') {
      lastChoisePlace.value.pickup = val
    } else {
      lastChoisePlace.value.dropoff = val
    }
  }

  const updateSuggestions = (val) => {
    data.value = val
  }

  const updatePathStartFinish = (val, type) => {
    if (type == 'pickup') {
      pathStartFinish.value.pickup = val
      pathStartFinish.value.valid.pickup = true
    } else {
      pathStartFinish.value.dropoff = val
      pathStartFinish.value.valid.dropoff = true
    }
  }

  const clearInput = () => {
    inputValue.value = ''
    data.value = []
  }

  const selectSuggestions = async (type, place_id) => {
    const response = await axios.get(`${apiInput}/details?place_id=${place_id}`)
    let latLng = await response.data.result.geometry.location

    if (!checkAddress(response.data.result, type)) return
    updatePathStartFinish(latLng, type)
  }

  // Проверка, находится ли страна в числе элементов
  const checkAddress = (address, type) => {
    const countries =
      'Austria, Belgium, Bulgaria, Greece, Denmark, Ireland, Spain, Italy, Cyprus, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Finland, France, Croatia, Czechia, Sweden, Estonia, Germany, Hungary, Switzerland, Vatican City, San Marino'
    let addressCollect = collect(address.address_components)
    let findedCountry = addressCollect
      .filter((e) => {
        return e.types.includes('country')
      })
      .first()

    if (findedCountry) {
      let countryChecked = findedCountry.long_name

      if (countries.includes(countryChecked)) {
        type == 'pickup'
          ? (pathStartFinish.value.valid.pickup = true)
          : (pathStartFinish.value.valid.dropoff = true)

        return true
      }

      type == 'pickup'
        ? (pathStartFinish.value.valid.pickup = false)
        : (pathStartFinish.value.valid.dropoff = false)
    }
    return false
  }

  let timer = false
  const fetchSuggestions = async (query, fieldType = false) => {
    clearTimeout(timer)
    timer = setTimeout(async () => {
      if (query.length === 0) return
      const queryParam = String(query).toLowerCase()
      try {
        const response = await axios.get(`${apiInput}/input?input=${queryParam}`)
        data.value = await response.data.predictions
        if (fieldType) showSuggestions.value[fieldType] = true
      } catch (error) {
        console.error('Error fetching suggestions')
      }
    }, 250)
  }

  const findPlace = async (query, callback) => {
    try {
      const response = await axios.get(`${apiInput}/findPlace?input=${query}`)
      callback(response.data)
      // const suggestions = await response.data.predictions
      // data.value = suggestions
    } catch (error) {
      console.error('Error fetching suggestions')
    }
  }

  return {
    inputValue,
    pickupRef,
    dropoffRef,
    data,
    pathStartFinish,
    activeAuto,
    showSuggestions,
    lastChoisePlace,
    placeChoised,
    resetPathStartFinish,
    updatePathStartFinish,
    findPlace,
    updatePlaceChoised,
    updateLastChoisePlace,
    checkAddress,
    selectSuggestions,
    updateSuggestions,
    fetchSuggestions,
    clearInput
  }
})
