<template>
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.66665 1.3335H8.33331C8.51711 1.3335 8.66665 1.98183 8.66665 2.16563V3.00016H5.33331V2.16563C5.33331 1.98183 5.48285 1.3335 5.66665 1.3335ZM12.6666 11.0002C12.6663 11.2653 12.5609 11.5195 12.3734 11.707C12.186 11.8944 11.9318 11.9998 11.6666 12.0002H2.33331C2.06819 11.9998 1.81401 11.8944 1.62654 11.707C1.43907 11.5195 1.33361 11.2653 1.33331 11.0002V5.00016C1.33361 4.73504 1.43907 4.48086 1.62654 4.29339C1.81401 4.10592 2.06819 4.00046 2.33331 4.00016H11.6666C11.9318 4.00046 12.186 4.10592 12.3734 4.29339C12.5609 4.48086 12.6663 4.73504 12.6666 5.00016V11.0002ZM11.6666 3.00016H9.66665V1.66683C9.66665 1.31321 9.52618 0.974069 9.27611 0.724023C9.02605 0.473969 8.68691 0.333496 8.33331 0.333496H5.66665C5.31303 0.333496 4.97389 0.473969 4.72384 0.724023C4.47379 0.974069 4.33331 1.31321 4.33331 1.66683V3.00016H2.33331C1.80288 3.00016 1.29417 3.21088 0.9191 3.58595C0.544026 3.96102 0.333313 4.46973 0.333313 5.00016V11.0002C0.333313 11.5306 0.544026 12.0393 0.9191 12.4144C1.29417 12.7894 1.80288 13.0002 2.33331 13.0002H11.6666C12.197 13.0002 12.7058 12.7894 13.0808 12.4144C13.4559 12.0393 13.6666 11.5306 13.6666 11.0002V5.00016C13.6666 4.46973 13.4559 3.96102 13.0808 3.58595C12.7058 3.21088 12.197 3.00016 11.6666 3.00016Z"
      :fill="fill"
    />
  </svg>
</template>

<script setup>
import { computed } from 'vue'

const colors = {
  rlt: '#af8a38',
  dli: '#2D8423',
  dgt: '#e5ba8c'
}

const fill = computed(() => {
  return colors[import.meta.env.VITE_PROJECT_ALIAS]
})
</script>

<style scoped></style>
