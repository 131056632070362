<template>
  <GMapMap
    @click="handleMapClick"
    ref="mapRef"
    :center="pathStartFinish.pickup"
    :zoom="zoomLevel"
    map-type-id="styledMap"
    class="mt-3 h-80 w-full"
    :options="{
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true
    }"
  >
    <GMapPolyline v-if="path" :path="path" :options="{ strokeColor: '#5FD052' }" />
    <GMapMarker
      @click="handleMarkerClick('pickup')"
      @dragstart="handleMarkerClick('pickup')"
      :position="pathStartFinish.pickup"
      :icon="marker"
      :draggable="true"
      @dragend="updateMarkerLocation('pickup', $event.latLng)"
    />
    <GMapMarker
      @click="handleMarkerClick('dropoff')"
      @dragstart="handleMarkerClick('dropoff')"
      :position="pathStartFinish.dropoff"
      :icon="marker"
      :draggable="true"
      @dragend="updateMarkerLocation('dropoff', $event.latLng)"
    />
  </GMapMap>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue'
import marker from '~project_assets/images/marker.svg'

// import { useGoogleAddress } from '@/compose/googleAddress'
import { useTrustyStore } from '@/stores/trustyComplete'
import { storeToRefs } from 'pinia'

const trustyStore = useTrustyStore()

const { activeAuto, pathStartFinish, pickupRef, dropoffRef } = storeToRefs(trustyStore)
const { checkAddress, updateLastChoisePlace, updatePlaceChoised } = trustyStore

const axios = inject('axios')
const mapRef = ref(null)
const requestTime = ref(false)
const path = ref(null)
const latRoadAbs = ref(null)
let mapInstance = ref()
let zoomLevel = ref(8)

const emit = defineEmits(['markerdragged'])

const props = defineProps({
  // pickupRef: String,
  // dropoffRef: String,
  form: Object,
  formName: String
})

// function handleMapClick(e) {
//   googleAddress.updatePickupDropOff(e.latLng, activeAuto.value)
// }

function handleMarkerClick(marker) {
  activeAuto.value = marker
}

function updateMarkerLocation(locationType, newLocation) {
  let latLng = { lat: newLocation.lat(), lng: newLocation.lng() }
  // googleAddress.updatePickupDropOff(latLng, locationType)
  emit('markerdragged', locationType)

  // eslint-disable-next-line
  const geocoder = new google.maps.Geocoder()

  geocoder.geocode({ location: latLng }, (results, status) => {
    // eslint-disable-next-line
    if (status === google.maps.GeocoderStatus.OK) {
      if (results[0]) {
        // console.log(results[0])
        if (!checkAddress(results[0], locationType)) return false

        if (locationType === 'pickup' && pickupRef.value) {
          pickupRef.value = results[0].formatted_address
          updateLastChoisePlace(
            {
              formatted_address: pickupRef.value,
              place_id: results[0].place_id
            },
            locationType,
            true
          )
          updatePlaceChoised(true, locationType)
          pathStartFinish.value.pickup.lat = results[0].geometry.location.lat()
          pathStartFinish.value.pickup.lng = results[0].geometry.location.lng()
          props.form.validate()
        } else if (locationType === 'dropoff' && dropoffRef.value) {
          dropoffRef.value = results[0].formatted_address
          updateLastChoisePlace(
            {
              formatted_address: dropoffRef.value,
              place_id: results[0].place_id
            },
            locationType,
            true
          )
          updatePlaceChoised(true, locationType)
          pathStartFinish.value.dropoff.lat = results[0].geometry.location.lat()
          pathStartFinish.value.dropoff.lng = results[0].geometry.location.lng()
          props.form.validate()
        }
      }
    }
  })
}

function fitMapBounds() {
  if (
    mapRef.value &&
    pathStartFinish.value.pickup &&
    pathStartFinish.value.dropoff &&
    mapRef.value.$mapObject
  ) {
    // eslint-disable-next-line
    const bounds = new google.maps.LatLngBounds()
    bounds.extend(pathStartFinish.value.pickup)
    bounds.extend(pathStartFinish.value.dropoff)

    let diffLat = Math.abs(pathStartFinish.value.pickup.lat - pathStartFinish.value.dropoff.lat)
    const diffLong = Math.abs(pathStartFinish.value.pickup.lng - pathStartFinish.value.dropoff.lng)
    if (diffLong < 0.01) {
      bounds.extend({
        lat: pathStartFinish.value.pickup.lat,
        lng: pathStartFinish.value.pickup.lng + 1
      })
      bounds.extend({
        lat: pathStartFinish.value.pickup.lat,
        lng: pathStartFinish.value.pickup.lng - 1
      })
    }
    // There is a location where we need to zoom out. Here, we check if the location matches the data in
    // props.form.values. If it matches, we zoom out by 0.7.
    mapRef.value.$mapObject.fitBounds(bounds)
    zoomLevel.value =
      mapRef.value.$mapObject.getZoom() -
      ((props.form.values.pickup === 'Florence, Metropolitan City of Florence, Italy' &&
        props.form.values.dropoff === 'Lucca, Province of Lucca, Italy') ||
      (props.form.values.pickup === 'Florence, Metropolitan City of Florence, Italy' &&
        props.form.values.dropoff === 'Pisa, Province of Pisa, Italy')
        ? 0.7
        : 0)

    // console.log(latRoadAbs.value, 'разница между самой высокой части дороги и самой низкой');
    // console.log(zoomLevel.value, 'Default zoom');

    if (diffLong > diffLat) {
      if (latRoadAbs.value >= 0.2 && latRoadAbs.value < 0.4 && zoomLevel.value >= 9.5) {
        zoomLevel.value -= 1
      } else if (latRoadAbs.value >= 0.4 && latRoadAbs.value < 0.6 && zoomLevel.value >= 9) {
        zoomLevel.value -= 0.85
      } else if (latRoadAbs.value >= 0.6 && latRoadAbs.value < 0.7 && zoomLevel.value >= 8) {
        zoomLevel.value -= 1.2
      } else if (latRoadAbs.value >= 0.7 && latRoadAbs.value < 0.8 && zoomLevel.value >= 8) {
        zoomLevel.value -= 1
      } else if (latRoadAbs.value >= 0.8 && latRoadAbs.value < 1.3 && zoomLevel.value >= 8) {
        zoomLevel.value -= 1
      } else if (latRoadAbs.value >= 1.3 && latRoadAbs.value < 1.5 && zoomLevel.value >= 7) {
        zoomLevel.value -= 0.8
      } else if (latRoadAbs.value >= 1.5 && latRoadAbs.value < 2.2 && zoomLevel.value >= 7) {
        zoomLevel.value -= 0.4
      }
    }
    // console.log(zoomLevel.value, 'Mod zoom');
  }
}

watch(
  pathStartFinish.value,
  (value) => {
    if (
      Object.prototype.hasOwnProperty.call(value, 'pickup') &&
      Object.prototype.hasOwnProperty.call(value, 'dropoff')
    ) {
      requestTime.value = true

      axios
        .post('/route/path', {
          origin: `${value.pickup.lat},${value.pickup.lng}`,
          destination: `${value.dropoff.lat},${value.dropoff.lng}`,
          key: import.meta.env.VITE_APP_GOOGLE_API_KEY
        })
        .then((value) => {
          requestTime.value = false

          if (!value.data?.data?.routes.length) return false

          // eslint-disable-next-line
          path.value = new google.maps.geometry.encoding.decodePath(
            value.data.data.routes[0].overview_polyline.points
          )
          fitMapBounds()
        })
        .catch((reason) => {
          console.log('catch:', reason)
        })
    }
  },
  { deep: true }
)
//
onMounted(() => {
  mapRef.value.$mapPromise.then((map) => {
    mapInstance.value = map
    // eslint-disable-next-line
    const styledMapType = new google.maps.StyledMapType([
      {
        featureType: 'administrative',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#444444'
          }
        ]
      },
      {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
          {
            color: '#f2f2f2'
          }
        ]
      },
      {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'road',
        elementType: 'all',
        stylers: [
          {
            saturation: -100
          },
          {
            lightness: 45
          }
        ]
      },
      {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
          {
            visibility: 'simplified'
          }
        ]
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'transit',
        elementType: 'all',
        stylers: [
          {
            visibility: 'off'
          }
        ]
      },
      {
        featureType: 'water',
        elementType: 'all',
        stylers: [
          {
            color: '#46bcec'
          },
          {
            visibility: 'on'
          }
        ]
      }
    ])

    map.mapTypes.set('styledMap', styledMapType)
  })

  fitMapBounds()
  window.vueGoogleMapsInit()

  requestTime.value = true
  if (props.formName == 'Tours' || props.formName == 'Hourly') {
    return
  }

  axios
    .post('/route/path', {
      origin: `${pathStartFinish.value.pickup.lat},${pathStartFinish.value.pickup.lng}`,
      destination: `${pathStartFinish.value.dropoff.lat},${pathStartFinish.value.dropoff.lng}`,
      key: import.meta.env.VITE_APP_GOOGLE_API_KEY
    })
    .then((value) => {
      requestTime.value = false
      // eslint-disable-next-line
      if (!value.data.data.routes[0]) return false

      // eslint-disable-next-line
      path.value = new google.maps.geometry.encoding.decodePath(
        value.data.data.routes[0].overview_polyline.points
      )

      if (value.data.data.routes[0]?.legs[0]?.steps) {
        let steps = value.data.data.routes[0].legs[0].steps

        let latMin = steps.reduce(function (x, y) {
          return Math.min(x, y.start_location.lat)
        }, 1000)

        let latMax = steps.reduce(function (x, y) {
          return Math.max(x, y.start_location.lat)
        }, 0)

        latRoadAbs.value = latMax - latMin
      }

      setTimeout(() => {
        fitMapBounds()
      }, 200)
    })
    .catch((reason) => {
      console.log(reason)
    })
})
</script>
