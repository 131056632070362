<template>
  <div class="absolute right-0 mt-[70px] flex items-end justify-end gap-2.5">
    <div v-if="isShown" class="bg-background h-full break-all rounded-md px-5 py-2.5 text-white">
      version-{{ version }}
    </div>
    <button @click="showVersion" class="bg-background h-full rounded-md p-1.5">
      <GitIcon />
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import GitIcon from '@/components/icons/GitIcon.vue'

const isShown = ref(false)
const version = computed(() => {
  return import.meta.env.VITE_PROJECT_VERSION
})

const showVersion = () => (isShown.value = !isShown.value)
</script>

<style scoped></style>
