<template>
  <svg
    width="20"
    height="32"
    viewBox="0 0 20 32"
    xmlns="http://www.w3.org/2000/svg"
    class="location_icon"
  >
    <path
      d="M11.1498 0.5C11.9571 0.720969 12.79 0.876278 13.5665 1.17553C17.4131 2.65413 19.9207 6.5255 19.4415 10.556C19.2493 12.1671 18.6662 13.7619 18.0743 15.2935C16.1855 20.1776 13.7228 24.7977 11.2037 29.3875C10.8321 30.0643 10.4489 30.7348 10.0197 31.5C9.96459 31.3927 9.93768 31.3371 9.90821 31.2841C6.9611 26.0478 4.10497 20.7647 1.91771 15.1647C1.36544 13.7518 0.914409 12.277 0.628668 10.7921C0.218636 8.66069 0.819589 6.66439 1.99074 4.86255C3.56552 2.43821 5.82326 1.00381 8.7268 0.584599C8.80112 0.573235 8.87159 0.529042 8.94335 0.5C9.67885 0.5 10.4143 0.5 11.1498 0.5ZM15.6615 9.86023C15.6615 6.80455 13.118 4.29435 10.0235 4.29308C6.92907 4.29308 4.39071 6.80202 4.39199 9.86149C4.39328 12.921 6.93035 15.4274 10.0248 15.4274C13.1193 15.4274 15.6615 12.9159 15.6615 9.86023Z"
      :fill="active ? '#5FD052' : '#A0AEC0'"
    />
  </svg>
</template>

<script>
export default {
  props: ['active']
}
</script>

<style scoped>
.icon-wrapper {
  width: 60px;
  height: 60px;
}
</style>
