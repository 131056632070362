<template>
  <div class="flex gap-3">
    <div class="min-w-[20px]">
      <PlaceIcon class="fill-[#B3B3B3] duration-300 dark:group-hover:fill-white" />
    </div>
    <p>{{ props.place.description }}</p>
  </div>
</template>

<script setup>
import PlaceIcon from '@/components/icons/PlaceIcon.vue'
const props = defineProps(['place'])
</script>
