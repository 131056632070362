import { defineStore } from 'pinia'
import { ref } from 'vue'

const STORE_NAME = 'cars'
const LOCALSTORAGE_KEY = 'cars'

export const useCarsStore = defineStore(STORE_NAME, () => {
  const defaultData = null

  const cars = ref(defaultData)
  const selectedCar = ref(defaultData)

  function update(data) {
    cars.value = data
    sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(data))
  }

  function selectCar(data) {
    selectedCar.value = data
    sessionStorage.setItem('selectedCar', JSON.stringify(selectedCar.value))
  }
  function $reset() {
    cars.value = null
    selectedCar.value = 0
    sessionStorage.removeItem(LOCALSTORAGE_KEY)
    sessionStorage.removeItem('selectedCar')
  }

  const loadDefaultValue = () => {
    const selectedCarData = sessionStorage.getItem('selectedCar')
    const localStorageData = sessionStorage.getItem(LOCALSTORAGE_KEY)
    if (localStorageData) {
      update(JSON.parse(localStorageData))
    } else {
      update(defaultData)
    }

    if (selectedCarData !== 'undefined' && selectedCarData) {
      selectCar(JSON.parse(selectedCarData))
    } else {
      selectedCar.value = null
    }
  }

  loadDefaultValue()

  return { cars, selectedCar, update, selectCar, $reset }
})
