<script setup></script>

<template>
  <svg
    class="fill-background dark:fill-white"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.53 1.52973L16.47 0.469727L8.99997 7.93876L1.52997 0.469727L0.469971 1.52973L7.93897 8.99976L0.469971 16.4698L1.52997 17.5298L8.99997 10.0608L16.47 17.5298L17.53 16.4698L10.061 8.99976L17.53 1.52973Z"
    />
  </svg>
</template>

<style scoped></style>
