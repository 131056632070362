import { defineStore } from 'pinia'
import { useFetcher } from '@/compose/axios'
import { jwtDecode } from 'jwt-decode'
import { ref } from 'vue'
import { Centrifuge } from 'centrifuge'

const STORE_NAME = 'centrifugo'
const LOCALSTORAGE_KEY = 'centrifugo'

export const useCentrifugoStore = defineStore(STORE_NAME, () => {
  const { axiosInstance } = useFetcher({ baseUrl: import.meta.env.VITE_APP_API_URL })
  const connection = ref(null)
  const token = ref(null)

  const refreshToken = async () => {
    await axiosInstance.post('/centrifuge/refresh-token').then(function (response) {
      sessionStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(response.data.data.token))
    })
  }

  const getToken = async () => {
    let data = sessionStorage.getItem(LOCALSTORAGE_KEY)

    if (data === null) {
      await refreshToken()
      data = sessionStorage.getItem(LOCALSTORAGE_KEY)
    }

    let jwtToken = JSON.parse(data)

    const decoded = jwtDecode(jwtToken)

    if (decoded.exp < (new Date().getTime() + 1) / 1000) {
      await refreshToken()
      data = sessionStorage.getItem(LOCALSTORAGE_KEY)
    }

    token.value = jwtToken
  }

  const connect = async (channels) => {
    await getToken()
    // Use WebSocket transport endpoint.
    connection.value = new Centrifuge(import.meta.env.VITE_CENTRIFUGO_BASE_URL, {
      token: token
    })

    channels.forEach((channel) => {
      let sub = connection.value.newSubscription(channel.name)

      // React on `subChannel` channel real-time publications.
      sub.on('publication', function (ctx) {
        if (ctx.channel === channel.name && ctx.data.event === channel.event) {
          channel.action(ctx.data)
        }
      })
      sub.subscribe()
    })

    // Trigger actual connection establishment.
    connection.value.connect()
  }

  const send = async (channel, data) => {
    connection.value.publish(channel, data)
  }

  return {
    refreshToken,
    getToken,
    connect,
    send
  }
})
