import axios from 'axios'

export default {
  install: (app, options) => {
    axios.defaults.headers.common = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    let axiosInstance = axios.create({
      baseURL: options.baseUrl
    })

    app.config.globalProperties.$axios = axiosInstance
    app.provide('axios', axiosInstance)
  }
}
