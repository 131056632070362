<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2H12C12.2757 2 12.5 2.9725 12.5 3.2482V4.5H7.5V3.2482C7.5 2.9725 7.7243 2 8 2ZM18.5 16.5C18.4995 16.8977 18.3414 17.279 18.0602 17.5602C17.779 17.8414 17.3977 17.9995 17 18H3C2.60231 17.9995 2.22104 17.8414 1.93984 17.5602C1.65863 17.279 1.50045 16.8977 1.5 16.5V7.5C1.50045 7.10231 1.65863 6.72104 1.93984 6.43984C2.22104 6.15863 2.60231 6.00045 3 6H17C17.3977 6.00045 17.779 6.15863 18.0602 6.43984C18.3414 6.72104 18.4995 7.10231 18.5 7.5V16.5ZM17 4.5H14V2.5C14 1.96957 13.7893 1.46086 13.4142 1.08579C13.0391 0.71071 12.5304 0.5 12 0.5H8C7.46957 0.5 6.96086 0.71071 6.58579 1.08579C6.21071 1.46086 6 1.96957 6 2.5V4.5H3C2.20435 4.5 1.44129 4.81607 0.87868 5.37868C0.31607 5.94129 0 6.70435 0 7.5V16.5C0 17.2956 0.31607 18.0587 0.87868 18.6213C1.44129 19.1839 2.20435 19.5 3 19.5H17C17.7956 19.5 18.5587 19.1839 19.1213 18.6213C19.6839 18.0587 20 17.2956 20 16.5V7.5C20 6.70435 19.6839 5.94129 19.1213 5.37868C18.5587 4.81607 17.7956 4.5 17 4.5Z"
      :fill="fill"
    />
  </svg>
</template>

<script setup>
import { computed } from 'vue'

const colors = {
  rlt: '#988049',
  dli: '#2D8423',
  dgt: '#b08f6c'
}

const fill = computed(() => {
  return colors[import.meta.env.VITE_PROJECT_ALIAS]
})
</script>

<style scoped></style>
