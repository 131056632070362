<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="mr-[6px]"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.017 1.802C8.28125 1.98759 6.63344 2.66056 5.26402 3.74312C3.89459 4.82569 2.85945 6.27366 2.27822 7.9197C1.69699 9.56574 1.5934 11.3427 1.97943 13.0451C2.36545 14.7475 3.22533 16.306 4.45969 17.5403C5.69405 18.7747 7.25249 19.6345 8.95492 20.0206C10.6573 20.4066 12.4343 20.303 14.0803 19.7218C15.7263 19.1406 17.1743 18.1054 18.2569 16.736C19.3394 15.3666 20.0124 13.7188 20.198 11.983C19.5644 12.7881 18.7673 13.4497 17.8594 13.9243C16.9515 14.3989 15.9533 14.6757 14.9306 14.7364C13.9079 14.7972 12.8839 14.6404 11.9262 14.2766C10.9685 13.9128 10.0987 13.3501 9.3743 12.6257C8.64987 11.9013 8.0872 11.0315 7.72338 10.0738C7.35955 9.11609 7.20284 8.09213 7.26357 7.06944C7.3243 6.04674 7.60108 5.04852 8.07569 4.14059C8.55029 3.23265 9.21193 2.43561 10.017 1.802ZM0.25 11C0.25 5.063 5.063 0.25 11 0.25C11.717 0.25 12.075 0.821 12.137 1.276C12.196 1.714 12.034 2.271 11.531 2.575C10.7839 3.02586 10.1493 3.64082 9.67514 4.37331C9.20098 5.10579 8.89974 5.93658 8.79425 6.80274C8.68875 7.6689 8.78177 8.54771 9.06625 9.3726C9.35073 10.1975 9.81921 10.9468 10.4362 11.5638C11.0532 12.1808 11.8025 12.6493 12.6274 12.9338C13.4523 13.2182 14.3311 13.3112 15.1973 13.2058C16.0634 13.1003 16.8942 12.799 17.6267 12.3249C18.3592 11.8507 18.9741 11.2161 19.425 10.469C19.729 9.966 20.286 9.804 20.724 9.863C21.179 9.925 21.75 10.283 21.75 11C21.75 16.937 16.937 21.75 11 21.75C5.063 21.75 0.25 16.937 0.25 11Z"
      fill="#2B2D32"
    />
  </svg>
</template>

<script setup></script>

<style scoped></style>
