<script setup></script>

<template>
  <svg
    class="stroke-[#2B2D32] dark:stroke-white"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2608_3290)">
      <path
        d="M8.00065 14.6673C11.6825 14.6673 14.6673 11.6825 14.6673 8.00065C14.6673 4.31875 11.6825 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065C1.33398 11.6825 4.31875 14.6673 8.00065 14.6673Z"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.33398 8H14.6673"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00065 1.33398C9.66817 3.15955 10.6158 5.52867 10.6673 8.00065C10.6158 10.4726 9.66817 12.8417 8.00065 14.6673C6.33313 12.8417 5.38548 10.4726 5.33398 8.00065C5.38548 5.52867 6.33313 3.15955 8.00065 1.33398Z"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<style scoped></style>
